import styled, { keyframes } from "styled-components";
import {
  Button,
  type ThemeType,
  Box,
  type SpaceType,
  Text,
  Heading,
  type ColorKeyType,
} from "@cruk/cruk-react-components";

// keeps content centered for very wide screen widths
export const ContentMaxWidth = styled(Box)<{
  theme: ThemeType;
}>`
  && {
    max-width: ${({ theme }) => theme.utilities.contentMaxWidth};
    margin: 0 auto;
  }
`;

export const ContentWrapperResponsive = styled(Box)<{
  theme: ThemeType;
}>`
  && {
    max-width: ${({ theme }) => theme.utilities.contentMaxWidth};
    margin: 0 auto;
    padding-left: ${({ theme }) => theme.spacing.xs};
    padding-right: ${({ theme }) => theme.spacing.xs};

    @media (min-width: ${({ theme }) => theme.breakpoint.mobile}) {
      padding-left: ${({ theme }) => theme.spacing.s};
      padding-right: ${({ theme }) => theme.spacing.s};
    }
  }
`;

// Flex stuff
export const Row = styled.div<{ $gap?: SpaceType; theme: ThemeType }>`
  && {
    display: flex;
    flex-direction: row;
    gap: ${({ theme, $gap }) =>
      !!$gap && !!theme.spacing[$gap]
        ? theme.spacing[$gap]
        : theme.spacing.none};
  }
`;

export const RowBottom = styled.div`
  && {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
`;

export const RowWrap = styled.div`
  && {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const RowStretch = styled.div`
  && {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const RowStretchFullWidthBreakPoint = styled.div<{
  theme: ThemeType;
}>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    justify-content: center;
    width: auto;
  }
`;

export const RowSpaceAround = styled.div`
  && {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
`;

export const RowCenter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const RowAligned = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const RowAlignCenter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const RowEnd = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const RowCenterMobile = styled.div<{
  theme: ThemeType;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoint.mobile}) {
    display: block;
  }
`;

export const BlockCenter = styled.div`
  > * {
    margin: auto;
  }
`;

export const ResponsiveRow = styled(Row)<{
  theme: ThemeType;
}>`
  display: block;
  @media (min-width: ${({ theme }) => theme.breakpoint.mobile}) {
    display: flex;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ColumnReverse = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

export const ColumnSpaceAround = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const ColumnCenter = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
`;

export const ColumnAlignCenter = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

type ListProps = {
  theme: ThemeType;
  $marginBottom?: SpaceType;
};

export const List = styled.ul<ListProps>`
  padding: 0;
  margin: 0;
  li {
    display: block;
    flex: 0 1 auto;
    margin-bottom: ${({ theme, $marginBottom }: ListProps) =>
      !!$marginBottom && !!theme.spacing[$marginBottom]
        ? theme.spacing[$marginBottom]
        : theme.spacing.xxs};

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const pulse = keyframes`
  0% {background-color: #eee;}
  50% {background-color: #ccc;}
  100% {background-color: #eee;}
`;

export const HeaderImage = styled.img`
  height: auto;
  width: 100%;
  margin: auto;
  vertical-align: bottom;
  animation: ${pulse} 3s ease-in-out infinite;
`;

// Boxes
export const DashedBorder = styled.div<{
  $color?: ColorKeyType;
  theme: ThemeType;
}>`
  position: relative;
  width: 100%;
  border: dashed 2px
    ${({ theme, $color }) =>
      $color ? theme.colors[$color] : theme.tokenColors.grey_300};
  padding: ${({ theme }) => theme.spacing.xs};
`;

export const Border = styled.div<{
  theme: ThemeType;
}>`
  width: 100%;
  border: solid 1px ${({ theme }) => theme.tokenColors.grey_300};
  padding: ${({ theme }) => theme.spacing.xs};
`;

export const TopBorder = styled(Box)<{
  $forceBorder?: boolean;
  theme: ThemeType;
}>`
  position: relative;
  width: 100%;
  border-style: solid;
  border-width: 1px 0 0 0;
  border-color: ${({ theme }) => theme.tokenColors.grey_300};

  &:first-of-type {
    padding-top: 0 !important;
    border-width: ${({ $forceBorder }) => ($forceBorder ? "1px 0 0 0" : "0")};
  }
`;

export const TopBorderChunky = styled(Box)<{ theme: ThemeType }>`
  width: 100%;
  border-style: solid;
  border-width: 4px 0 0 0;
  border-color: ${({ theme }) => theme.colors.primary};
`;

//  lists

export const MemberList = styled.ul<{
  theme: ThemeType;
}>`
  padding: 0;
  margin: 0;
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  column-gap: ${({ theme }) => theme.spacing.s};
  row-gap: ${({ theme }) => theme.spacing.s};

  li {
    vertical-align: top;
    display: inline-block;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
  }
`;

export const StyledFormActions = styled.div<{
  theme: ThemeType;
}>`
  &&& {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-end;

    > a,
    button {
      width: 100%;
      padding: 0;
      margin: 0 0 ${({ theme }) => theme.spacing.xxs} 0;
    }

    > a:last-child,
    button:last-child {
      margin: 0;
    }

    @media (min-width: ${({ theme }) => theme.breakpoint.mobile}) {
      > a,
      button {
        width: auto;
        padding: 0 ${({ theme }) => theme.spacing.s};
        margin: 0 ${({ theme }) => theme.spacing.xxs} 0 0;
      }

      > a:last-child,
      button:last-child {
        margin: 0;
      }
    }
  }
`;

// Form styles
export const Divider = styled.div<{
  theme: ThemeType;
}>`
  align-items: center;
  display: flex;
  flex-basis: 100%;
  text-align: center;
  width: 100%;
  &:before,
  &:after {
    background-color: ${({ theme }) => theme.colors.textDark};
    content: "";
    display: inline-block;
    flex-grow: 1;
    height: 1px;
  }
  &:before {
    margin-right: ${({ theme }) => theme.spacing.xs};
  }
  &:after {
    margin-left: ${({ theme }) => theme.spacing.xs};
  }
`;

export const DividerLight = styled.div<{
  theme: ThemeType;
}>`
  align-items: center;
  display: flex;
  flex-basis: 100%;
  text-align: center;
  width: 100%;
  &:after {
    background-color: ${({ theme }) => theme.tokenColors.grey_300};
    content: "";
    display: inline-block;
    flex-grow: 1;
    height: 1px;
  }
`;

// Setting max width for fields rather than % so fields don't get smaller unless they have to.
// Using rems so the form and fields increase with the font size.
// We can probably just set strings bellow. Could be added to inputs in the design system.
const formWidth = 36;
const fieldWidths = {
  full: formWidth,
  medium: formWidth / 1.5,
  small: formWidth / 3,
};

export const FormWrapper = styled(Box)`
  margin-right: auto;
  margin-left: auto;
  max-width: ${formWidth}rem;
`;

export const FieldWrapper = styled.div<{
  $inputWidth?: "full" | "medium" | "small";
  $marginBottom?: SpaceType;
  theme: ThemeType;
}>`
  input,
  select {
    max-width: ${({ $inputWidth }) =>
      $inputWidth ? fieldWidths[$inputWidth] : fieldWidths.full}rem;
  }
  margin-bottom: ${({ theme, $marginBottom }): string =>
    $marginBottom ? theme.spacing[$marginBottom] : theme.spacing.xl};

  &:last-of-type {
    margin-bottom: ${({ theme, $marginBottom }) =>
      $marginBottom ? theme.spacing[$marginBottom] : 0};
  }
`;

export const FormFieldWrapper = styled.div<{
  theme: ThemeType;
}>`
  label {
    width: 100%;
    margin-bottom: ${({ theme }) => theme.spacing.s};
  }
`;

export const FilterWrapper = styled.div`
  max-width: 26em;
`;

export const StyledFieldset = styled.fieldset<{
  $marginBottom?: SpaceType;
  theme: ThemeType;
}>`
  padding: 0;
  margin-bottom: ${({ theme, $marginBottom = "xl" }) =>
    theme.spacing[$marginBottom]};
  border: none;

  label {
    &:not(:last-of-type) {
      margin-bottom: ${({ theme }) => theme.spacing.s};
    }
  }
`;

// Custom buttons
export const TabButton = styled.button<{
  $active: boolean;
  theme: ThemeType;
}>`
  flex: 1 1;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  border-width: 0 0 4px 0;
  border-color: rgba(0, 0, 0, 0);
  border-style: solid;
  border-bottom-color: ${({ theme, $active }) =>
    $active ? theme.colors.primary : "rgba(0, 0, 0, 0)"};

  &:hover {
    cursor: pointer;
  }
`;

export const TabRow = styled(RowStretch)<{ theme: ThemeType }>`
  border-bottom: solid 1px ${({ theme }) => theme.tokenColors.grey_300};
`;

export const FacebookButton = styled(Button)<{ theme: ThemeType }>`
  background-color: #1b74e4;
  border-color: #1b74e4;
  color: white !important;
  &:not(:disabled):hover,
  &:not(:disabled):focus {
    background-color: #165db6;
    border-color: #165db6;
    color: white !important;
  }
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    width: auto;
  }
`;

export const FitbitButton = styled(Button)<{ theme: ThemeType }>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #00b0b9;
  border-color: #00b0b9;
  color: black !important;
  &:hover,
  &:focus {
    background-color: #008d94;
    border-color: #008d94;
    color: white !important;
  }
  height: 1rem;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    width: auto;
  }

  img {
    height: 2.5em;
    vertical-align: sub;
  }
`;

export const StravaButton = styled(Button)<{ theme: ThemeType }>`
  background-color: #fc5400;
  border-color: #fc5400;
  color: white !important;
  &:hover,
  &:focus {
    background-color: #ca4300;
    border-color: #ca4300;
    color: white !important;
  }
  height: 1rem;
  width: 100%;
  @media (min-width: ${({ theme }) => theme.breakpoint.tablet}) {
    width: auto;
  }
`;

// Text truncation
export const TextMultiLineEllipsis = styled(Text)<{ $lineCount?: number }>`
  width: 100%;
  word-break: break-word;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${({ $lineCount }) => $lineCount || 3};
  -webkit-box-orient: vertical;
`;

export const HeadingMultiLineEllipsis = styled(Heading)<{
  $lineCount?: number;
  theme: ThemeType;
}>`
  width: 100%;
  white-space: wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${({ $lineCount }) => $lineCount};
  -webkit-box-orient: vertical;

  transition: color 0.2s ease;
  color: ${({ theme }) => theme.colors.primary};

  @media (min-width: ${({ theme }) => theme.breakpoint.mobile}) {
    margin-bottom: ${({ theme }) => theme.spacing.s};
  }
`;

export const SingleLineElipsis = styled(Text)<{ theme: ThemeType }>`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  height: ${({ theme }) => theme.typography.lineHeight};
`;

export const ActivityIconWrapper = styled.div<{
  $isEmpty?: boolean;
  $isEditing?: boolean;
  theme: ThemeType;
}>`
  position: relative;
  color: ${({ theme, $isEmpty = false, $isEditing = false }) =>
    $isEditing || !$isEmpty ? theme.colors.secondary : theme.colors.disabled};
  margin: ${({ theme }) => `0 ${theme.spacing.xs} 0 0`};

  height: ${({ $isEditing = false }) => ($isEditing ? "2em" : "1.5em")};
  margin-bottom: ${({ theme, $isEditing = false }) =>
    $isEditing ? theme.spacing.xs : 0};
  width: 2em;

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    flex-direction: column;
    height: 2em;
    margin: ${({ theme }) => `0 0 ${theme.spacing.xs} 0`};
    width: auto;
  }
`;

export const ActivityItem = styled.div<{
  $canEdit?: boolean;
  $isEditing?: boolean;
  theme: ThemeType;
}>`
  display: flex;
  flex-direction: ${({ $isEditing }) => ($isEditing ? "column" : "row")};
  justify-content: flex-start;
  text-align: left;

  svg {
    transition: transform ease 0.3s;
  }

  &:hover {
    div > svg {
      transform: ${({ $canEdit = false, $isEditing = false }) =>
        $isEditing || !$canEdit ? `scale(1)` : `scale(1.5)`};
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    flex-direction: column;
    flex: 0 1;
    text-align: center;
  }
`;

export const ActivityItemWrapper = styled.div<{
  theme: ThemeType;
}>`
  margin-bottom: ${({ theme }) => theme.spacing.xs};
  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    margin-bottom: 0;
    width: 33.33%;
    margin: 0 auto;
  }
`;

export const LeftAlign = styled(Box)`
  text-align: left;
`;

export const ScreenReaderOnly = styled.span`
  border: 0px;
  clip: rect(0px, 0px, 0px, 0px);
  height: 1px;
  margin-bottom: -1px;
  margin-right: -1px;
  overflow: hidden;
  padding: 0px;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

// page styles

export const HeaderGroup = styled.div<{
  theme: ThemeType;
}>`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
    flex-direction: column-reverse;
  }
`;

export const AvatarResponsiveWrapper = styled(Box)<{
  theme: ThemeType;
}>`
  && {
    padding: ${({ theme }) => `0 ${theme.spacing.s}`};

    @media (min-width: ${({ theme }) => theme.breakpoint.desktop}) {
      padding: ${({ theme }) => `${theme.spacing.xs} ${theme.spacing.s}`};
    }
  }
`;
